import React, { useEffect, useRef, forwardRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Input, Button } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import sendImg from '../../../../statics/send.png'
import loadingImg from '../../../../statics/loading.png'
import sendActiveImg from '../../../../statics/send_active.png'
import thingkingLoadingImg from '../../../../statics/thinking_loading.png'
import materialImg from '../../../../statics/material_mode.png'
import materialActiveImg from '../../../../statics/material_mode_active.png'
import reasoningImg from '../../../../statics/reasoning.png'
import reasoningActiveImg from '../../../../statics/reasoning_active.png'


const MessageInput = forwardRef((props, ref) => {
    const { handleOnBlur, handleOnFocus, ...rest } = props
    const dispatch = useDispatch()
    const textAreaRef = ref
    let prompt = useSelector(state => state.get('home').get('prompt'))
    let is_thinking = useSelector(state => state.get('home').get('is_thinking'))
    let is_online_search = useSelector(state => state.get('home').get('is_online_search'))
    let is_reasoning = useSelector(state => state.get('home').get('is_reasoning'))

    useEffect(() => {
        if (!is_thinking) {
            textAreaRef.current && textAreaRef.current.focus()
        }
    }, [is_thinking])

    const handleStopResponding = () => {
        dispatch(actionCreators.stopFetchResponding())
        dispatch(actionCreators.isStopFetchResponding(true))
        setTimeout(() => {
            console.log("test");
            dispatch(actionCreators.isStopFetchResponding(false))
        }, 2000); // 持续时间要比Message组件内部的定时器长一点确保它在动画完成后再消失
    }

    const pressEnter = (e) => {
        if (e.key === 'Enter' && e.keyCode === 13 && e.shiftKey) {
            // === 无操作 ===
        } else if (e.key === 'Enter' && e.keyCode === 13) {
            onSubmit()
            e.preventDefault() //禁止回车的默认换行
        }
    }

    const onChange = (e) => {
        let value = e.target.value
        dispatch(actionCreators.setPromptAction(value))
    }

    const onSubmit = () => {
        if (is_thinking || prompt.trim() === '') {
            return
        }

        dispatch(actionCreators.sendAskAction(prompt))
    }

    const onBlur = () => {
        handleOnBlur()
    }

    const onFocus = () => {
        handleOnFocus()
    }

    const handleOnlineSearch = () => {
        dispatch(actionCreators.setIsOnlineSearch(!is_online_search))
    }

    const handleIsReasoning = () => {
        dispatch(actionCreators.setIsReasoning(!is_reasoning))
    }

    return (
        <div className={styles.textarea_box}>
            <Input.TextArea
                value={prompt}
                size="large"
                placeholder={is_thinking ? "正在思考中..." : "发送你的问题给灵石AI..."}
                autoSize={{
                    maxRows: 10
                }}
                onPressEnter={pressEnter}
                onChange={onChange}
                ref={textAreaRef}
                className={styles.custom_textarea}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {
                is_thinking ? (
                    <div
                        className={styles.thinking_loading_box}
                        onClick={handleStopResponding}
                    >
                        <img
                            src={thingkingLoadingImg}
                            className={styles.circle_img}
                        />
                        <div className={styles.square_img}></div>
                    </div>
                ) : (
                    prompt.trim() !== '' && !is_thinking ? (
                        <img
                            src={sendActiveImg}
                            className={styles.send_butn_img}
                            onClick={onSubmit}
                            alt="发送"
                        />
                    ) : (
                        <img
                            src={sendImg}
                            className={styles.send_butn_img}
                        />
                    )
                )
            }
            <div className={styles.reasoning_box}>
                <div
                    className={`${styles.reasoning} ${is_reasoning ? styles.reasoning_active : null}`}
                    onClick={handleIsReasoning}
                >
                    {
                        is_reasoning ? (
                            <img src={reasoningActiveImg} className={styles.reasoning_img} alt="icon" />
                        ) : (
                            <img src={reasoningImg} className={styles.reasoning_img} alt="icon" />
                        )
                    }

                    <span className={styles.reasoning_text}>高级推理</span>
                </div>

                <div
                    className={`${styles.reasoning} ${is_online_search ? styles.reasoning_active : null}`}
                    onClick={handleOnlineSearch}
                >
                    {
                        is_online_search ? (
                            <img src={materialActiveImg} className={styles.reasoning_img} alt="icon" />
                        ) : (
                            <img src={materialImg} className={styles.reasoning_img} alt="icon" />
                        )
                    }

                    <span className={styles.reasoning_text}>联网搜索</span>
                </div>
            </div>
        </div>
    )
})

export default MessageInput