import React, { Component, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, message, Upload, Select, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { actionCreators } from '../../store';
import MyTipTapTextEditor from '../../../../common/MyTipTapTextEditor'
import { arraysAreEqual } from '../../../../utils'


function ComponentListedModalEdit({isModalOpen, setIsModalOpen, currentComponentType}) {
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [fileList, setFileList] = useState([])
    const [sideIconList, setSideIconList] = useState([])
    const [isPublic, setIsPublic] = useState(true)
    const [imageLogoPreview, setImageLogoPreview] = useState('');
    const [imageSideLogoPreview, setImageSideLogoPreview] = useState('');
    const [editorText, setEditorText] = useState('')
    const [form] = Form.useForm();
    let component_picture_listed = useSelector(state => state.get('admin').get('component_picture_listed'))
    let component_document_listed = useSelector(state => state.get('admin').get('component_document_listed'))
    let component_listed_select_menu = useSelector(state => state.get('admin').get('component_listed_select_menu')).toJS()
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))
    const [pictureComponents, setPictureComponents] = useState([])
    const [documentComponents, setDocumentComponents] = useState([])

    useEffect(() => {
        let list = component_picture_listed.toJS()
        let picture_components = list.map((item) => {
            return {
                label: item.name,
                value: item.name,
            }
        })
        setPictureComponents(picture_components)
    }, [component_picture_listed])

    useEffect(() => {
        let list = component_document_listed.toJS()
        let document_components = list.map((item) => {
            return {
                label: item.name,
                value: item.name,
            }
        })
        console.log("document_components = ", document_components);
        setDocumentComponents(document_components)
    }, [component_document_listed])

    useEffect(() => {
        if (isModalOpen) {
            let list = []
            let obj = {}
            if (currentComponentType === 'document_kb') {
                list = component_document_listed.toJS()
                obj = list.find(item => item.name === current_edit_component_name)
            } else {
                list = component_picture_listed.toJS()
                obj = list.find(item => item.name === current_edit_component_name)
            }

            if (obj) {
                setImageLogoPreview(obj.avatar)
                setImageSideLogoPreview(obj.avatar_tb)
                setEditorText(obj.metadata?.rich_text ? obj.metadata?.rich_text : '')
                setIsPublic(obj.is_public)
                console.log("obj = ", obj)

                form.setFieldsValue({
                    component_name: obj.name || '',
                    category: obj.category || '',
                    overview: obj.overview || '',
                    bind_components: obj.attached_components || [],
                })

                if (currentComponentType === 'document_kb') {
                    form.setFieldsValue({
                        description: obj.description || '',
                    })
                }
            }
        }
        console.log("==========================");
    }, [isModalOpen, current_edit_component_name, component_picture_listed, component_document_listed, form])

    const onFinish = (values) => {
        console.log('Success:', values);
        let category = values.category
        let component_name = values.component_name.trim()
        let overview = values.overview.trim()
        let bind_components = values.bind_components
        let list = component_picture_listed.toJS().concat(component_document_listed.toJS())
        let description = values?.description ? values?.description.trim() : ''

        let exists = list.find(item => item.name === component_name)
        console.log("exists = ", exists)
        if (component_name === '') {
            message.info("知识库名称不能为空或空格！")
        } else if (exists && component_name !== current_edit_component_name) {
            message.info("当前知识库名称已存在，请重新换一个名字~")
        } else if (overview === '') {
            message.info("模型描述信息不能为空！")
        } else if (editorText === '') {
            message.info("问答示例不能为空！")
        } else {
            let obj = list.find(item => item.name === current_edit_component_name) || {}
            let formData = new FormData();
            let times = 0;

            if (fileList.length !== 0) {
                formData.append('avatar', fileList[0].originFileObj)
                times += 1
                console.log("avatar change");
            }

            if (sideIconList.length !== 0) {
                formData.append('avatar_tb', sideIconList[0].originFileObj)
                times += 1
                console.log("avatar_tb change");
            }

            formData.append('name', current_edit_component_name)
            if (component_name !== current_edit_component_name) {
                formData.append('new_name', component_name)
                times += 1
                console.log("new_name change");
            }

            if (obj.overview !== overview) {
                formData.append('overview', overview)
                times += 1
                console.log("overview change");
            }

            formData.append('weight', 0)

            if (obj.category !== category) {
                formData.append('category', category)
                times += 1
                console.log("category change");
            }

            if (obj.metadata?.rich_text !== editorText) {
                formData.append('meta_data', JSON.stringify({rich_text: editorText}))
                times += 1
                console.log("meta_data change");
            }

            if (currentComponentType === 'document_kb') {
                formData.append('component_type', obj.r_type)
                if (!arraysAreEqual(obj.attached_components, bind_components)) {
                    // formData.append('attached_component_names', bind_components ? bind_components : null)
                    if (bind_components.length !== 0) {
                        bind_components.forEach(element => {
                            formData.append('attached_component_names', element)
                        });
                    } else {
                        formData.append('attached_component_names', null)
                    }

                    times += 1
                    console.log("document attached_component_names change");
                }

                if (description !== obj.description) {
                    formData.append('description', description)
                    times += 1
                    console.log("document description change");
                }
            } else {
                formData.append('component_type', obj.r_type)
                if (bind_components && (obj.is_public !== isPublic || !arraysAreEqual(obj.attached_components, bind_components))) {
                    formData.append('is_public', isPublic)
                    times += 1
                    console.log("is_public change");
                    if (!isPublic) {
                        if (!arraysAreEqual(obj.attached_components, bind_components)) {

                            // formData.append('attached_component_names', bind_components ? bind_components : null)
                            if (bind_components.length !== 0) {
                                bind_components.forEach(element => {
                                    formData.append('attached_component_names', element)
                                });
                            } else {
                                formData.append('attached_component_names', null)
                            }

                            times += 1
                            console.log("picture attached_component_names change");
                        }
                    }
                }
            }

            console.log("times = ", times);
            if (times !== 0) {
                console.log("avatar = ", formData.get('avatar'));
                console.log("avatar_tb = ", formData.get('avatar_tb'));
                console.log("name = ", formData.get('name'));
                console.log("overview = ", formData.get('overview'));
                console.log("weight = ", formData.get('weight'));
                console.log("category = ", formData.get('category'));
                console.log("meta_data = ", formData.get('meta_data'));
                console.log("component_type = ", formData.get('component_type'));
                console.log("is_public = ", formData.get('is_public'));
                console.log("attached_component_names = ", formData.get('attached_component_names'));

                setCreating(true)
                dispatch(actionCreators.createOrUpdateComponent(formData))
                    .then(data => {
                        if (currentComponentType === 'document_kb') {
                            dispatch(actionCreators.getComponentListed('document_kb'))
                        } else {
                            dispatch(actionCreators.getComponentListed('picture_kb'))
                        }
                        handleCloseModalLocal()
                    })
                    .catch(error => {})
                    .finally(() => {setCreating(false)})
            } else {
                message.info("没有任何改动")
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleCloseModalLocal = () => {
        form.resetFields();
        setFileList([])
        setImageLogoPreview('')
        setSideIconList([])
        setImageSideLogoPreview('')
        setEditorText('')
        setIsModalOpen(false)
    }

    const handleUploadChange = (info) => {
        console.log("handleUploadChange info = ", info);
        let newFileList = [...info.fileList];
        // 只保留最后一个选择的文件
        newFileList = newFileList.slice(-1);
        setFileList(newFileList)

        // 使用 FileReader 读取文件并生成 Data URL
        const reader = new FileReader();
        reader.onload = (e) => {
            setImageLogoPreview(e.target.result);
        };
        reader.readAsDataURL(info.file);
    }

    const handleUploadSideChange = (info) => {
        console.log("handleUploadSideChange info = ", info);
        let newFileList = [...info.fileList];
        // 只保留最后一个选择的文件
        newFileList = newFileList.slice(-1);
        setSideIconList(newFileList)

        // 使用 FileReader 读取文件并生成 Data URL
        const reader = new FileReader();
        reader.onload = (e) => {
            setImageSideLogoPreview(e.target.result);
        };
        reader.readAsDataURL(info.file);
    }

    const handleNameChange = (e) => {
        let value = e.target.value
        // TODO
        // setTempComponent(prevState => ({
        //     ...prevState,
        //     name: value
        // }));
    }

    const handleIntroduceTextareaChange = (e) => {
        let value = e.target.value
        // TODO
        // setTempComponent(prevState => ({
        //     ...prevState,
        //     overview: value
        // }));
    }

    const handleDescriptionTextareaChange = (e) => {
        let value = e.target.value
        // TODO
        // setTempComponent(prevState => ({
        //     ...prevState,
        //     description: value
        // }));
    }

    return (
        <>
            <Modal
                title={currentComponentType === 'document_kb' ? "编辑文本组件" : "编辑图片组件"}
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                centered
                width={1500}
                footer={false}
                destroyOnClose={true}
                className={styles.modal_container}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                >
                    <div className={styles.main}>
                        <div className={styles.main_left}>
                            <div className={styles.left_header}>前台信息</div>
                            <div className={styles.left_content}>
                                <Form.Item
                                    label="上传logo"
                                    name="logo"
                                    labelAlign="left"
                                >
                                    <div className={styles.right_header_img_box}>
                                        <Upload
                                            name="upload_logo"
                                            listType="picture"
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                if (!isJpgOrPng) {
                                                message.error('目前只允许上传 JPG/PNG 的图片！');
                                                }
                                                const isLt10M = file.size / 1024 / 1024 < 10;
                                                if (!isLt10M) {
                                                    message.error('图片目前限制要小于 10MB！');
                                                }
                                                return false;
                                            }}
                                            showUploadList={false}  // 不显示上传的文件列表
                                            onChange={handleUploadChange}
                                        >
                                            <Button icon={<UploadOutlined />} className={styles.right_header_butn}>更换图片</Button>
                                        </Upload>
                                        <img src={imageLogoPreview} className={styles.right_header_img} alt="logo" />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="上传侧边栏logo"
                                    name="side_logo"
                                    labelAlign="left"
                                >
                                    <div className={styles.right_header_img_box}>
                                        <Upload
                                            name="upload_side_logo"
                                            listType="picture"
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                if (!isJpgOrPng) {
                                                message.error('目前只允许上传 JPG/PNG 的图片！');
                                                }
                                                const isLt10M = file.size / 1024 / 1024 < 10;
                                                if (!isLt10M) {
                                                    message.error('图片目前限制要小于 10MB！');
                                                }
                                                return false;
                                            }}
                                            showUploadList={false}  // 不显示上传的文件列表
                                            onChange={handleUploadSideChange}
                                        >
                                            <Button icon={<UploadOutlined />} className={styles.right_header_butn}>更换图片</Button>
                                        </Upload>
                                        <img src={imageSideLogoPreview} className={styles.right_header_img} alt="side logo" />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="组件名称"
                                    name="component_name"
                                    labelAlign="left"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入组件名称',
                                        },
                                    ]}
                                >
                                    <Input
                                        type={"text"}
                                        placeholder={"组件名称"}
                                        // value={"todo"}
                                        className={styles.name_input}
                                        // onChange={handleNameChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="介绍"
                                    name="overview"
                                    rules={[{ required: true, message: '请在这里写下介绍' }]}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Input.TextArea
                                        style={{
                                            resize: 'none',
                                            width: '100%',
                                            height: 100,
                                        }}
                                        placeholder="请在这里写下介绍..."
                                        // onChange={handleIntroduceTextareaChange}
                                        // value={"todo"}
                                        className={styles.custom_textarea}
                                    >
                                    </Input.TextArea>
                                </Form.Item>
                                <Form.Item
                                    label="例子（问答示例）"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <MyTipTapTextEditor
                                        editorText={editorText}
                                        setEditorText={setEditorText}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={styles.main_right}>
                            <div className={styles.right_header}>后台信息</div>
                            <div className={styles.right_content}>
                                {/*
                                <Form.Item
                                    label="模型的展示描述"
                                    name="overview"
                                    rules={[{ required: true, message: '请输入模型的展示描述' }]}
                                >
                                    <Input.TextArea
                                        // showCount
                                        // maxLength={100}
                                        style={{
                                            resize: 'none',
                                            width: '100%',
                                            height: 100,
                                        }}
                                        onChange={(e) => {console.log('Change:', e.target.value)}}
                                        placeholder="展示给用户的模型描述信息"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="模型的代理描述"
                                    name="description"
                                    rules={[{ required: true, message: '请输入给后端代理模型的描述信息' }]}
                                >
                                    <Input.TextArea
                                        // showCount
                                        // maxLength={100}
                                        style={{
                                            resize: 'none',
                                            width: '100%',
                                            height: 100,
                                        }}
                                        onChange={(e) => {console.log('Change:', e.target.value)}}
                                        placeholder="给后端代理模型的描述信息（灵石据此选择合适的专家模型）"
                                    />
                                </Form.Item> */}

                                {/* ==================== */}

                                <Form.Item
                                    label="模型的分类"
                                    name="category"
                                    labelAlign="left"
                                    rules={[{ required: true, message: '请选择模型对应的分类' }]}
                                >
                                    <Select
                                        style={{
                                            width: 120,
                                        }}
                                        onChange={(e) => { console.log("选择的数据为 ", e); }}
                                        options={component_listed_select_menu}
                                    />
                                </Form.Item>

                                {
                                    currentComponentType === 'document_kb' ? (
                                        <>
                                            <Form.Item
                                                label="绑定图片组件"
                                                name="bind_components"
                                                labelAlign="left"
                                                // rules={[{ required: true, message: '请选择绑定图片组件' }]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    onChange={(e) => { console.log("选择的数据为 ", e); }}
                                                    options={pictureComponents}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="模型的代理描述"
                                                name="description"
                                                labelAlign="left"
                                                rules={[{ required: true, message: '请输入给后端代理模型的描述信息' }]}
                                            >
                                                <Input.TextArea
                                                    // showCount
                                                    // maxLength={100}
                                                    style={{
                                                        resize: 'none',
                                                        width: '100%',
                                                        height: 100,
                                                    }}
                                                    onChange={(e) => {console.log('Change:', e.target.value)}}
                                                    placeholder="给后端代理模型的描述信息（帮助大模型选择合适的组件）"
                                                />
                                            </Form.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Form.Item
                                                label="是否默认为公共图片组件"
                                                name="public"
                                                labelAlign="left"
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 14 }}
                                            >
                                                <Checkbox
                                                    checked={isPublic}
                                                    onChange={() => {setIsPublic(!isPublic)}}
                                                >
                                                </Checkbox>
                                            </Form.Item>

                                            {
                                                isPublic ? null : (
                                                    <Form.Item
                                                        label="绑定文本组件"
                                                        name="bind_components"
                                                        labelAlign="left"
                                                        // rules={[{ required: true, message: '请选择绑定图片组件' }]}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            onChange={(e) => { console.log("选择的数据为 ", e); }}
                                                            options={documentComponents}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Form.Item>
                            {
                                creating ? (
                                    <Button type="primary" loading>
                                        更新中
                                    </Button>
                                ) : (
                                    <Button type="primary" htmlType="submit">
                                        更新
                                    </Button>
                                )
                            }
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default ComponentListedModalEdit